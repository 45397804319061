import { css } from '@emotion/react'
import { graphql } from 'gatsby'
import {
  ComponentPropsWithoutRef,
  Dispatch,
  Fragment,
  SetStateAction,
  useEffect,
  useState,
} from 'react'
import { useInView } from 'react-intersection-observer'

import { DatoGatsbyImage } from '@/components/DatoGatsbyImage'
import { DatoStructuredText } from '@/components/DatoStructuredText'
import { useElementHeight } from '@/hooks/useElementRect'
import { absoluteFill, bezier, mq, scrim } from '@/theme/mixins'
import { colors } from '@/theme/variables'

interface Props extends ComponentPropsWithoutRef<'div'> {
  data?: Queries.AwardRecipientRecentFragment | null
  bgColor: 'WHITE' | 'GRAY'
  carouselHeight: number | null
  setCarouselHeight: Dispatch<SetStateAction<number | null>>
  thumbnailStyle:
    | Queries.AwardSectionFragment['thumbnailStyle']
    | undefined
}

export const AwardRecipientRecent = ({
  data,
  bgColor,
  carouselHeight,
  setCarouselHeight,
  thumbnailStyle,
  ...props
}: Props): JSX.Element => {
  const getThumbnailImage = () => {
    switch (thumbnailStyle) {
      case 'CIRCLE': {
        return data?.image?.circle
      }
      case 'SQUARE': {
        return data?.image?.square
      }
    }
  }
  const { inView, ref: inViewRef } = useInView({
    rootMargin: '20% -50%',
  })
  const [heightRef, setHeightRef] = useState<HTMLElement | null>(null)
  const height = useElementHeight(heightRef)
  useEffect(() => {
    if (inView && height) {
      setCarouselHeight(height)
    }
  }, [inView, height, setCarouselHeight])
  const styles = {
    container: css`
      position: relative;
      display: grid;
      grid-template-columns: auto 1fr;
      grid-column-gap: 2em;
      align-items: flex-start;
      padding: 2em 3em 2em 2em;
      box-sizing: border-box;
      overflow: hidden;
      transition: opacity 500ms ${bezier.easeOut};
      ${mq().ms} {
        padding: 1.5em;
        grid-column-gap: 1.5em;
      }
      ${mq().s} {
        padding: 1.5em var(--margin);
        grid-column-gap: 1em;
      }
      ${!inView &&
      css`
        opacity: 0.5;
        pointer-events: none;
        > div {
          transition: opacity 500ms ${bezier.easeOut};
          opacity: 0.5;
        }
      `}
      ${bgColor === 'WHITE' &&
      css`
        background-color: ${colors.gray95};
      `}
      ${bgColor === 'GRAY' &&
      css`
        background-color: ${colors.gray10};
      `}
    `,
    image: css`
      width: calc(1.333 * var(--fs-144));
      position: relative;
      grid-row: 1 / 3;
      z-index: 2;
      ${thumbnailStyle === 'CIRCLE' &&
      css`
        [data-gatsby-image-wrapper] img {
          border-radius: 50%;
        }
        &:after {
          content: '';
          ${absoluteFill}
          border-radius: 50%;
          border: 1px solid #00000011;
          box-sizing: border-box;
        }
      `}
      ${mq().m} {
        grid-row: auto;
      }
    `,
    heading: css`
      align-self: center;
      ${mq().m} {
        margin-bottom: 0.75em;
      }
    `,
    name: css`
      font-size: var(--fs-24);
      font-family: var(--ff-body);
      font-weight: 425;
      margin: 0.75em 0 0.333em;
      ${mq().m} {
        margin: 0.5em 0 0.333em;
      }
    `,
    details: css`
      margin: 0;
      font-weight: 300;
      line-height: 1.333;
      > span {
        display: inline-block;
      }
      ${bgColor === 'WHITE' &&
      css`
        color: ${colors.gray45};
      `}
      ${bgColor === 'GRAY' &&
      css`
        color: ${colors.gray65};
      `}
    `,
    subheading: css`
      font-weight: 375;
    `,
    awardYear: css`
      font-weight: 375;
    `,
    graduationYear: css`
      font-weight: 375;
      font-style: italic;
    `,
    description: css`
      grid-column: 2 / 3;
      ${mq().m} {
        grid-column: 1 / -1;
      }
      &:before {
        content: '';
        position: absolute;
        height: 6em;
        width: 100%;
        left: 0;
        top: calc(${height}px - 6em);
        opacity: 0;
        transition:
          opacity 500ms ${bezier.easeOut},
          top 0ms linear;
        ${bgColor === 'WHITE' &&
        css`
          background: ${scrim(colors.gray95)};
        `}
        ${bgColor === 'GRAY' &&
        css`
          background: ${scrim(colors.gray10)};
        `}
      }
      ${!inView &&
      css`
        opacity: 0.5;
        &:before {
          opacity: 1;
          top: calc(${carouselHeight}px - 6em);
          transition:
            opacity 500ms ${bezier.easeOut} 500ms,
            top 0ms linear 500ms;
        }
      `}
    `,
  }
  return (
    <div
      css={styles.container}
      ref={node => {
        setHeightRef(node)
        inViewRef(node)
      }}
      {...props}
    >
      <div css={styles.image}>
        <DatoGatsbyImage
          image={getThumbnailImage()}
          alt={data?.image?.alt || data?.name || ''}
        />
      </div>

      <div css={styles.heading}>
        <h3 css={styles.name}>{data?.name}</h3>
        <h4 css={styles.details}>
          {data?.subheading && (
            <Fragment>
              <span css={styles.subheading}>{data?.subheading}</span>
              &ensp;|&ensp;
            </Fragment>
          )}
          <span css={styles.awardYear}>
            {data?.awardYear} Recipient
          </span>
          {data?.graduationYear && (
            <Fragment>
              &ensp;|&ensp;
              <span css={styles.graduationYear}>
                Class of {data?.graduationYear}
              </span>
            </Fragment>
          )}
        </h4>
      </div>
      <div css={styles.description}>
        <DatoStructuredText data={data?.description} />
      </div>
    </div>
  )
}

export const AwardRecipientRecentFragment = graphql`
  fragment AwardRecipientRecent on DatoCmsAwardRecipientRecent {
    id: originalId
    __typename
    name
    subheading
    description {
      value
    }
    image {
      circle: gatsbyImageData(
        width: 360
        imgixParams: { fit: "crop", crop: "focalpoint", ar: "1:1" }
      )
      square: gatsbyImageData(width: 360)
      ...ImageFocalData
    }
    awardYear
    graduationYear
  }
`
